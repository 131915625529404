import { useEffect, useState } from "react";

export const useGA4 = (id, enableTracking, cookiesAllowed) => {
  const [ga4Initialized, setGa4Initialized] = useState(false);

  useEffect(() => {
    if ((id && enableTracking && cookiesAllowed && !ga4Initialized)) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;

      document.head.appendChild(script);

      const dataLayerPush = document.createElement("script");
      dataLayerPush.type = "text/javascript";
      const code = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${id}');
      `;

      try {
        dataLayerPush.appendChild(document.createTextNode(code));
        document.body.appendChild(dataLayerPush);
      } catch (e) {
        dataLayerPush.text = code;
        document.body.appendChild(dataLayerPush);
      }

      setGa4Initialized(true);
    }
  }, [id, enableTracking, cookiesAllowed, ga4Initialized]);
};

export const ga4SignUpEvent = () => {
  if ("gtag" in window) {
    window.gtag("event", "sign_up");
  }
};

export const ga4AddToCartEvent = (currency, value, items) => {
  if ("gtag" in window) {
    window.gtag("event", "add_to_cart", {
      currency,
      value,
      items,
    });
  }
};

export const ga4AddPaymentInfoEvent = (
  currency,
  value,
  coupon,
  paymentType,
  items
) => {
  if ("gtag" in window) {
    window.gtag("event", "add_payment_info", {
      currency,
      value,
      coupon,
      payment_type: paymentType,
      items,
    });
  }
};

export const ga4PurchaseEvent = (
  currency,
  transactionId,
  value,
  coupon,
  shipping,
  tax,
  items
) => {
  if ("gtag" in window) {
    window.gtag("event", "purchase", {
      currency,
      transaction_id: transactionId,
      value,
      coupon,
      shipping,
      tax,
      items,
    });
  }
};
