import React from "react"
import { clsxm } from '../../helpers/styling';

function Button({ children, className, backgroundColor, backgroundColorHover, backgroundColorFocus, border, borderColor, textColor, disabled, ...props }) {
    //bg-surface w-full border-solid border-2 border-accent-selected text-accent-selected font-semibold py-2 px-4 rounded uppercase
    return <button {...props} disabled={disabled} className={clsxm(border && `border-solid border border-${borderColor}`, `bg-${backgroundColor}`, (backgroundColorHover && !disabled) && `hover:bg-${backgroundColorHover}`, (backgroundColorFocus && !disabled) && `focus:bg-${backgroundColorFocus}`, `transition-colors`, `text-${textColor}`, `font-semibold`, `py-2`, `px-4`, `rounded-lg`, disabled && 'opacity-50', className)}>
        {children}
    </button>;
}

Button.defaultProps = {
    backgroundColor: "accent",
    backgroundColorHover: "accent-hovered",
    backgroundColorFocus: "accent",
    borderColor: "accent",
    textColor: "white",
    border: false,
    type: 'button',
    className: ''
}

export default Button;