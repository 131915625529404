import React from 'react';
import { clsxm } from '../../helpers/styling';
import Dialog from '@mui/material/Dialog';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Modal(props) {
    const fullScreen = useMediaQuery('(min-width:768px)');

    return <Dialog
        open={props.visible}
        onClose={() => props.setVisible(false)}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
        maxWidth="xl"
        PaperProps={{
            style: { borderRadius: '20px' }
        }}
    >
        {/* <!-- Modal content --> */}
        <div className="relative bg-white shadow rounded-none md:rounded-2xl">
            {/* <!-- Modal header --> */}
            <div className="flex justify-between items-center p-3 pl-6 pb-0 border-surface-focused">
                <h3 className="text-lg text-primary-text font-semibold">
                    {props.title}
                </h3>
                <button type="button" className="text-primary-text bg-transparent hover:bg-primary-text/25 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center" onClick={(e) => props.setVisible(false)}>
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                </button>
            </div>
            {/* <!-- Modal body --> */}
            <div className={"p-6 space-y-6" + (props.className ? ' ' + props.className : '')}>
                {props.children}
            </div>
        </div>
    </Dialog>;
}