import React, { useEffect, useState, Suspense } from "react";
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import swell from 'swell-js';
import { AccountContext, IsAuthingContext } from "../context/account.context";
import { DashboardAccountContext } from "../context/dashboard.account.context";
import Splashscreen from "./Splashscreen/Splashscreen";
import { useCookieModal } from "../components/input/CookieModal";
import { ETracker } from "../components/trackers/ETracker";
import { TrackingContext } from "../context/tracking.context";
import { FbPixel } from "../components/trackers/FbPixel";
import { GoogleAnalytics } from "../components/trackers/GoogleAnalytics";
import { GoogleAds } from "../components/trackers/GoogleAds";
import { LeadForensics } from "../components/trackers/LeadForensics";
import { MsClarity } from "../components/trackers/MsClarity";
import GA4 from "../components/trackers/GA4";

const routes = [
    {
        path: '/',
        element: React.lazy(() => import('./Main/MainPage'))
    },
    {
        path: '/products',
        element: React.lazy(() => import('./Main/Products'))
    },
    {
        path: '/contact',
        element: React.lazy(() => import('./Main/ContactPage'))
    },
    {
        path: '/signin',
        element: React.lazy(() => import('./Accounts/SignIn'))
    },
    {
        path: '/signup',
        element: React.lazy(() => import('./Accounts/SignUp'))
    },
    {
        path: '/signout',
        element: React.lazy(() => import('./Accounts/SignOut'))
    },
    {
        path: '/profile',
        element: React.lazy(() => import('./Accounts/Profile'))
    },
    {
        path: '/profile/:section',
        element: React.lazy(() => import('./Accounts/Profile'))
    },
    {
        path: '/profile/:section/:id',
        element: React.lazy(() => import('./Accounts/Profile'))
    },
    {
        path: '/forgot/:key',
        element: React.lazy(() => import('./Accounts/Recover'))
    },
    {
        path: '/order/complete',
        element: React.lazy(() => import('./CardOrder/Complete'))
    },
    {
        path: '/order/wunderlink-complete',
        element: React.lazy(() => import('./CardOrder/WunderlinkComplete'))
    },
    {
        path: '/order/:id',
        element: React.lazy(() => import('./CardOrder/CardOrder'))
    },
    //Dashboard
    {
        path: '/dashboard',
        element: React.lazy(() => import('./Dashboard/Dashboard'))
    },
    {
        path: '/dashboard/login',
        element: React.lazy(() => import('./Dashboard/DashboardAuth'))
    },
    {
        path: '/dashboard/orders',
        element: React.lazy(() => import('./Dashboard/OrderList'))
    },
    {
        path: '/dashboard/orders/:id',
        element: React.lazy(() => import('./Dashboard/OrderDetail'))
    },
    {
        path: '/*',
        element: React.lazy(() => import('./NotFound/NotFound'))
    },
    //Wunderlink
    {
        path: '/wunderlink/:link',
        element: React.lazy(() => import('./Wunderlink/Landing'))
    }
]

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnmount: false,
            refetchOnReconnect: false,
            retry: false,
            staleTime: 600000, //10 min
        },
    },
});

swell.init(
    process.env.REACT_APP_SWELL_STORE_ID,
    process.env.REACT_APP_SWELL_STORE_PUBLICKEY
);

export default function Pages(props) {
    const enableTracking = !!process.env.REACT_APP_ENABLE_TRACKING;
    const [cookieSettings, setCookieSettings] = useState(localStorage.getItem('cookieSettings'));
    const { showPrompt: showCookiePrompt, render: renderCookiePrompt } = useCookieModal();

    const userState = useState();
    const [userData, setUserData] = userState;

    const authingState = useState(true);
    const [isAuthing, setIsAuthing] = authingState;

    const dashboardUserState = useState();
    const [adminData, setAdminData] = dashboardUserState;

    const trackingState = useState();
    const [trackingData, setTrackingData] = dashboardUserState;

    useEffect(() => {
        if (!cookieSettings) {
            showCookiePrompt({ title: 'Cookies' }).then(() => {
                localStorage.setItem('cookieSettings', 'unrestricted');
                setCookieSettings('unrestricted');
            }).catch((err) => {
                localStorage.setItem('cookieSettings', 'restricted');
                setCookieSettings('restricted');
            });
        }
    }, [cookieSettings]);

    useEffect(() => {
        if (!userData)
            swell.account.get().then((ret) => {
                if (ret) setUserData(ret);
            }).finally(() => {
                setIsAuthing(false);
            });
    }, [userData, setUserData]);

    useEffect(() => {
        if (adminData) {
            localStorage.setItem('jwtToken', JSON.stringify(adminData.token));
            localStorage.setItem('userInfo', JSON.stringify(adminData.user));
        } else {
            if (localStorage.getItem("jwtToken") && localStorage.getItem("userInfo")) {
                try {
                    setAdminData({ token: JSON.parse(localStorage.getItem("jwtToken")), user: JSON.parse(localStorage.getItem("userInfo")) });
                } catch (err) {
                    localStorage.removeItem("jwtToken");
                }
            }
        }
    }, [adminData]);

    return (
        <>
            <Suspense fallback={<Splashscreen />}>
                <QueryClientProvider client={queryClient}>
                    <TrackingContext.Provider value={trackingState}>
                        <IsAuthingContext.Provider value={authingState}>
                            <AccountContext.Provider value={userState}>
                                <DashboardAccountContext.Provider value={dashboardUserState}>
                                    <BrowserRouter>
                                        {/* <ETracker id={process.env.REACT_APP_ETRACKER_ID} enableTracking={process.env.REACT_APP_ETRACKER_ID && enableTracking} cookieSettings={cookieSettings} /> */}
                                        {/* <FbPixel id={process.env.REACT_APP_PIXEL_ID} enableTracking={process.env.REACT_APP_PIXEL_ID && enableTracking} cookieSettings={cookieSettings} /> */}
                                        {/*TODO: remove fallbacks from trackers below*/}
                                        <GA4 id={process.env.REACT_APP_GA4_ID} enableTracking={process.env.REACT_APP_GA4_ID && enableTracking} cookieSettings={cookieSettings} />
                                        {/* <GoogleAds
                                            id={process.env.REACT_APP_GOOGLE_ADS_ID ?? 'AW-10987328709'}
                                            conversionLabel={process.env.REACT_APP_GOOGLE_ADS_CONVERSION_LABEL ?? 'UNDcCOX1_uEDEMWplfco'}
                                            enableTracking={enableTracking}
                                            cookieSettings={cookieSettings}
                                        /> */}
                                        {/* <GoogleAnalytics id={process.env.REACT_APP_GA_ID ?? 'UA-233959508-1'} enableTracking={enableTracking} cookieSettings={cookieSettings} /> */}
                                        {/* <LeadForensics id={process.env.REACT_APP_LEAD_FORENSICS_ID ?? 264527} enableTracking={enableTracking} cookieSettings={cookieSettings} /> */}
                                        {/* <MsClarity id={process.env.REACT_APP_MSCLARITY_ID ?? 'e0ii47jl27'} enableTracking={enableTracking} cookieSettings={cookieSettings} /> */}
                                        <Routes>
                                            {routes.map((route) => <Route key={route.path} path={route.path} element={<route.element />} />)}
                                        </Routes>
                                    </BrowserRouter>
                                </DashboardAccountContext.Provider>
                            </AccountContext.Provider>
                        </IsAuthingContext.Provider>
                    </TrackingContext.Provider>
                </QueryClientProvider>
            </Suspense>
            {renderCookiePrompt()}
        </>
    );
}