export const fileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

function toDataURL(src, callback, outputFormat) {
    if (!src) callback('');

    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        var canvas = document.createElement('CANVAS');
        var ctx = canvas.getContext('2d');
        var dataURL;
        canvas.height = this.naturalHeight;
        canvas.width = this.naturalWidth;
        ctx.drawImage(this, 0, 0);
        dataURL = canvas.toDataURL(outputFormat);
        callback(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
        img.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
        img.src = src;
    }
}

export const imageToBase64 = function (url) {
    return new Promise((resolve, reject) => {
        toDataURL(url, (b64) => {
            resolve(b64);
        });
    });
};

export async function localImageEquals(localB64, remoteUrl) {
    const remoteData = await imageToBase64(remoteUrl);
    return localB64 === remoteData;
}

export const dataURLtoFile = (dataurl, filename) => {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
        u8arr[n - 1] = bstr.charCodeAt(n - 1)
        n -= 1 // to make eslint happy
    }
    return new File([u8arr], filename, { type: mime })
}

export function resampleCanvas(canvas, width, height, resize_canvas) {
    var width_source = canvas.width;
    var height_source = canvas.height;
    width = Math.round(width);
    height = Math.round(height);

    var ratio_w = width_source / width;
    var ratio_h = height_source / height;
    var ratio_w_half = Math.ceil(ratio_w / 2);
    var ratio_h_half = Math.ceil(ratio_h / 2);

    var ctx = canvas.getContext("2d");
    var img = ctx.getImageData(0, 0, width_source, height_source);
    var img2 = ctx.createImageData(width, height);
    var data = img.data;
    var data2 = img2.data;

    for (var j = 0; j < height; j++) {
        for (var i = 0; i < width; i++) {
            var x2 = (i + j * width) * 4;
            var weight = 0;
            var weights = 0;
            var weights_alpha = 0;
            var gx_r = 0;
            var gx_g = 0;
            var gx_b = 0;
            var gx_a = 0;
            var center_y = (j + 0.5) * ratio_h;
            var yy_start = Math.floor(j * ratio_h);
            var yy_stop = Math.ceil((j + 1) * ratio_h);
            for (var yy = yy_start; yy < yy_stop; yy++) {
                var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
                var center_x = (i + 0.5) * ratio_w;
                var w0 = dy * dy; //pre-calc part of w
                var xx_start = Math.floor(i * ratio_w);
                var xx_stop = Math.ceil((i + 1) * ratio_w);
                for (var xx = xx_start; xx < xx_stop; xx++) {
                    var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
                    var w = Math.sqrt(w0 + dx * dx);
                    if (w >= 1) {
                        //pixel too far
                        continue;
                    }
                    //hermite filter
                    weight = 2 * w * w * w - 3 * w * w + 1;
                    var pos_x = 4 * (xx + yy * width_source);
                    //alpha
                    gx_a += weight * data[pos_x + 3];
                    weights_alpha += weight;
                    //colors
                    if (data[pos_x + 3] < 255)
                        weight = weight * data[pos_x + 3] / 250;
                    gx_r += weight * data[pos_x];
                    gx_g += weight * data[pos_x + 1];
                    gx_b += weight * data[pos_x + 2];
                    weights += weight;
                }
            }
            data2[x2] = gx_r / weights;
            data2[x2 + 1] = gx_g / weights;
            data2[x2 + 2] = gx_b / weights;
            data2[x2 + 3] = gx_a / weights_alpha;
        }
    }
    //clear and resize canvas
    if (resize_canvas === true) {
        canvas.width = width;
        canvas.height = height;
    } else {
        ctx.clearRect(0, 0, width_source, height_source);
    }

    //draw
    ctx.putImageData(img2, 0, 0);
}

export const loadImageAsync = function (src) {
    return new Promise((resolve, reject) => {
        const imageObj = new Image();
        imageObj.onload = () => {
            resolve(imageObj);
        }
        imageObj.onerror = () => {
            reject();
        }
        imageObj.src = src;
    });
};

export const autoResize = async (image, canvas, widthInMillimeters, heightInMillimeters, minDpi, maxDpi) => {
    const imageObj = await loadImageAsync(image);

    const ctx = canvas.getContext("2d");

    ctx.canvas.width = imageObj.width;
    ctx.canvas.height = imageObj.height;

    ctx.drawImage(imageObj, 0, 0);

    const widthInInches = widthInMillimeters / 25.4;
    const heightInInches = heightInMillimeters / 25.4;

    const fieldAspectRatio = widthInMillimeters / heightInMillimeters;
    const imageAspectRatio = ctx.canvas.width / ctx.canvas.height;

    const aspectRatioCorrect = fieldAspectRatio < imageAspectRatio;
    const dpi = aspectRatioCorrect ? ctx.canvas.width / widthInInches : ctx.canvas.height / heightInInches;

    if (dpi < minDpi) return null;
    if (dpi > maxDpi) {
        const newWidth = aspectRatioCorrect ? widthInInches * maxDpi : heightInInches * maxDpi / ctx.canvas.height * ctx.canvas.width;
        const newHeight = aspectRatioCorrect ? widthInInches * maxDpi / ctx.canvas.width * ctx.canvas.height : heightInInches * maxDpi;
        resampleCanvas(canvas, newWidth, newHeight, true);
    }
    return canvas.toDataURL("image/png");
}